.openk9-comune-milano {
  @import "./variables.scss";
  @import "./buttons.scss";
  @import "./inputs.scss";
  @import "./checkboxes.scss";
  @import "./grid-layout-3-template.scss";
  @import "./box.scss";
  @import "./typography.scss";
  @import "./util.scss";
  
  color: $stone;
  font-family: $sans-font-family;

  form[role="search"] {
    .openk9-sort-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: end;
      gap: 10px;

      #custom-select-sort {
        height: 52px;
        border-color: $red;
        color: $red;
        padding: 7px 25px 7px 12px;
      }
    }
    #accordion-view-filter:focus {
      //TODO: remove !important
      box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848 !important;
      outline: 0 !important;
    }
  }

  .openk9-no-border {
    border: unset !important;
  }

  .title-icon {
    height: 24px;

    path {
      fill: $red;
    }
  }
  .box-title {
    background-color: white;
    border-bottom: 1px solid $light-grey;
    border-radius: 8px 8px 0 0;
    width: auto;
    padding: calc($spacing-base * 2);
  }
  .openk9-label {
    color: $grey;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.lc {
      text-transform: initial;
    }
    &.sm {
      font-size: 14px;
    }
    &.lg {
      font-size: 20px;
    }
    &.light {
      font-weight: 400;
    }
    &.darken {
      color: $dark-grey;
    }
    &.letter-spacing-lg {
      letter-spacing: 0.05em;
    }
    &.multi-line {
      white-space: initial;
      text-overflow: initial;
      overflow: initial;
    }
  }
  .form-check-label {
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: normal;
    color: black;
  }
  .openk9-icon-label {
    display: flex;
    align-items: center;
    margin-left: 1px;
    margin-bottom: 1px;
    .openk9-icon {
      display: flex;

      &:first-child {
        margin-right: calc($spacing-base * 1);
      }
      &:last-child {
        margin-left: calc($spacing-base * 1);
        margin-left: 2px !important;
      }
    }
  }
  .openk9-simple-list {
    padding-left: 30px;

    li {
      @extend .openk9-label, .openk9-label, .light, .lc, .multi-line, .darken;

      list-style: square;
    }
  }
  // TABS
  .tabs-container-comune-frontend {
    overflow-x: auto;
  }
  
  .tabs-container-comune-frontend::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .tabs-container-comune-frontend::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .tabs-container-comune-frontend::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    height: 5px;
  }
  
  .tabs-container-comune-frontend::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.55);
    height: 5px;
  }
  
  
  .openk9-single-tab-container {
    padding-top: 0;
    &:focus-visible {
      box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
      outline: 0;
    }
  }
  .openk9-single-tab {
    color: $red;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    box-sizing: border-box;
    line-height: 30px;
    padding: 4px 22px;
    white-space: nowrap;
    background-color: $light-stone;

    &.variant {
      color: inherit;
      background-color: $mid-stone;

      &:not(:first-child) {
        margin-left: 0;
      }
    }
    &.openk9-active-tab {
      border: 2px solid $red;
    }
    &:not(:first-child) {
      margin-left: calc($spacing-base * 1);
    }
  }
  .openk9-tabs-overlay-scrollbars {
    margin-top: calc($spacing-base * 2);
  }

  // SEARCHBAR
  .openk9--search-container {
    gap: 0;
  }
  .openk9-focusable:has(input:focus) {
    box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
    outline: 0;
  }
  .openk9-embeddable-search--input-container {
    @include inputs;
  }
  .openk9--search-container-show-syntax {
    .openk9-token-select-is-open .openk9-token-select-container-highlighted{
      border-bottom:1px solid lightgray
    }
    .openk9--search-show-syntax {
      display: block; 
      width: 100%;
      .openk9-token-select-is-open {
        width: calc(100% + 96px);
        top: 45px;
        left: -49px;
        margin-top: 0;
        .openk9-enter-token {
          .openk9-token-select-uncheck {
            display: none;
          }
        }
        .openk9--search-show-syntax .openk9-token-select-is-open{
        border:none;
        }
        .openk9-token-select-factory-container-option-label{
          background: rgb(247 170 170 / 41%) !important
        }
        .openk9-enter-token{
          display: none;
        }
        .openk9-token-select-container-highlighted {
          padding: 10px 16px;
          &:hover {
            .openk9-token-select-factory-autocomplete {
              font-weight: bold;
            }
          }
          .openk9-token-select-factory-autocomplete {
            font-family: $sans-font-family;
          }
        }
      }
    }
    .openk9--input-search {
      &::placeholder {
        font-size: 14px;
        font-family: $sans-font-family, sans-serif;
      }
    }
  }
  .openk9--search-icon {
    // TODO: remove !important...
    padding-left: calc($spacing-base * 2) !important;
    padding-right: calc($spacing-base * 2);
  }
  .openk9-trash-logo-color {
    fill: white;
  }
  .openk9--search-delete-container-icon {
    // TODO: remove !important...
    margin-right: 0 !important;
    padding: 0 calc($spacing-base * 2) !important;
    &:focus {
      box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
      outline: 0;
    }
  }
  .openk9-delete-logo-color{
    fill: #919191;
  }
  .openk9--search-calendar-container {
    display: none;
  }
  .openk9--search-delete-span-icon {
    display: contents;
  }
  .label-search {
    display: none;
  }
  // SORT
  .openk9-sort-result-select {
    @include inputs;

    padding: 0 8px;
  }
  .select-custom-openk9 {
    border: 1px solid #747481 !important;
    border-radius: 4px;
   
    &:hover {
      border: 1px solid $red !important;
    }
  }
  .openk9-react-select__control {
    border-radius: 0px !important;
    min-height: 52px !important;
    border: 1px solid $grey !important;
    background: white !important;
  }
  .openk9-react-select__single-value {
    color: $grey !important;
    font-weight: 800 !important;
  }
  .openk9-react-select__control:hover {
    border: 1px solid $red !important;
  }
  .openk9-container-sort-result-list-component {
    display: block;
    margin-top: 12px;
  }
  // FILTERS
  // .horizontal-filter-load-more {
  //   border: 1px solid $red;
  //   color: $red;
  //   &:focus {
  //     box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
  //     outline: 0;
  //   }
  // }
  // .openk9-filter-list-container-title,
  // .openk9-number-filter-list-container-wrapper,
  // .openk9-filter-category-container-search {
  //   display: none;
  // }
  // .openk9-filters-horizontal-category {
  //   color: inherit;
  //   margin-top: 0;
  //   margin-bottom: calc($spacing-base * 1);
  // }
  // .openk9-filters-horizontal-container {
  //   gap: 8px;
  //   margin-bottom: calc($spacing-base * 2);
  // }
  // .openk9-container-active-filters {
  //   .openk9-active-filter {
  //     border: 1px solid $red;
  //     &:focus-visible {
  //       box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
  //       outline: 0;
  //     }
  //   }
  // }
  // .openk9-active-filter {
  //   &:focus-visible {
  //     box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
  //     outline: 0;
  //   }
  // }
  // .openk9-active-filters-chop-container {
  //   margin-top: 5px;
  //   margin-left: 5px;
  //   > .openk9-active-filter {
  //     font-weight: 400;
  //     color: inherit;
  //   }
  // }
  // .openk9-comune-milano .openk9-tabs-overlay-scrollbars {
  //   margin-top: 0px;
  // }
  // .openk9-filter-horizontal-container-input-value {
  //   display: flex;
  //   align-items: center;
  //   margin-top: 3px;
  //   margin-bottom: 3px;
  //   .openk9-filter-horizontal-input {
  //     @include checkboxes;
  //     margin: 4px;
  //   }
  // }
  // .openk9-filter-horizontal-submit {
  //   @extend .btn, .btn-primary;
  //   @media (max-width: 480px) {
  //     min-height: 40px;
  //   }
  // }
  // .openk9-close-filters {
  //   display: none;
  // }
  // .openk9-filter-form-check-container {
  //   padding-left: 0;
  // }
  // .openk9-filter-category-title {
  //   margin-left: 0;
  //   margin-bottom: calc($spacing-base * 1);
  // }
  // .openk9-create-label-container-wrapper {
  //   @extend .btn-link, .btn-link-primary;

  //   margin: 0;
  //   padding: 0;

  //   .openk9-icon-plus {
  //     display: none;
  //   }
  // }
  // .openk9-create-label-container-text-style {
  //   margin: 0;
  // }
  // .openk9-filter-category-container {
  //   @media (max-width: 480px) {
  //     padding-inline: 16px !important;
  //   }
  // }
  // .filter-dynamic-check {
  //   @include checkboxes;
  // }
  // .filter-category-radio-checked {
  //   background-color: $red !important;
  // }
  // .container-openk9-filter-mobile-live-change {
  //   gap: 20px;
  //   min-height: 35px;
  // }
  // .live-change-box {
  //   display: flex;
  //   background-color: rgb(250, 250, 250);
  // }
  // .container-openk9-filter-mobile-live-change {
  //   display: flex;
  //   justify-content: space-between;
  // }
  .title-icon {
    @media (max-width: 480px) {
      height: 16px;
    }
  }
  .openk9-container-load-more {
    width: auto;
    margin-left: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .openk9-embeddable-result-list-button-load-more {
    border: 1px solid $red;
    color: $red;
    border-radius: 4px;
    padding: 7px 10px;
    font-size: 16px;
    font-weight: 500;
    &:focus-visible {
      box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
      outline: 0;
    }
  }
  .openk9-embeddable-search--result-container {
    &:focus-visible {
      outline: 2px solid $red;
    }
    cursor: unset;
  }
  .openk9-active-container-box {
    align-items: baseline;
  }
  .openk9-filter-horizontal-container-submit {
    gap: 20px;
  }
  // .comune-milano .openk9-filters-horizontal-container {
  //   padding-bottom: 20px;
  // }
  .openk9-filter-category-no-results-is-open {
    &:before {
      background-image: url("../image/comune-milano-logo.png");
      content: "";
      width: 165px;
      height: 85px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .openk9-logo {
      display: none;
    }
  }
  // RESULTS
  .openk9-number-result-list-container-wrapper {
    display: none;
  }
  .openk9-title-result {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    height: 18px;
    line-height: 22px;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #3f3f46;
    margin: 0;
    &:focus-visible {
      box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
      outline: 0;
    }
  }
  .openk9-result-list-counter-number {
    font-size: 15px;
    color: $red;
  }
  .openk9-embeddable-search--result-container {
    @include box;

    &:hover {
      @include boxHighlight;
    }
  }
  .button-return-cards {
    color: $red;
    background: #f7eef0;
  }
  .openk9-detail-class-title {
    &:focus-visible {
      box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
      outline: 0;
    }
  }
  .openk9-wrapper-button-mobile {
    padding: 0;
    button {
      @extend .btn-link, .btn-link-primary, .sm, .uc;
      background-color: initial;
      border: 0;
      display: flex !important;
      svg {
        fill: $red;
      }
    }
  }
  // card
  .openk9-embeddable-result-container {
    padding: 0;
  }
  .openk9-detail-web-button {
    &:focus-visible {
      border-radius: 5px;
    }
  }
  .openk9-detail-container-card {
    padding: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .aElement {
    &:focus {
      box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
      outline: 0;
    }
  }
  .openk9-embeddable-result-title-component,
  .openk9-embeddable-detail-title {
    font-weight: 600;
    white-space: initial;
    line-height: 1.2em;
  }
  .openk9-embeddable-result--children {
    width: 100%;
    margin: 0;
  }
  .openk9-embeddable-result-container-media {
    display: none;
  }
  .openk9-embeddable-detail-container {
    @include box;
    @include boxHighlight;
  }
  .openk9-result-summary {
    color: $dark-grey-secondary;
    font-size: 18px;
    line-height: 22px;
  }
  .openk9-spacer {
    margin: calc($spacing-base * 2) 0;
  }
  .openk9-separator {
    @extend .openk9-spacer;

    border-top: 1px solid #e5e5e5;
  }
  .openk9-embeddable--result-text-content,
  .openk9-embeddable-detail-text-content {
    @extend .openk9-result-summary;
    color: $dark-grey-secondary;
  }
  .openk9-embeddable-high-light {
    color: $red;
    font-weight: 500;
  }
  // detail
  .openk9-informative-set {
    display: flex;
    gap: calc($spacing-base * 1);
    align-items: center;
    margin: calc($spacing-base * 1) 0;

    .openk9-informative-set-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      letter-spacing: 0.02em;
      overflow: hidden;
    }
    .openk9-informative-set-download {
      padding: calc($spacing-base * 1);
    }
  }
  .openk9-dataset-3-template {
    @include grid-layout-3-template;
  }

  // Search Suggestion
  .openk9-token-select-is-open {
    @include box;
    // @include boxHighlight;

    background-color: white;
    padding: 0;
    margin: 0;
    border-radius: 0px;
    & > div:first-child {
      border-bottom: 1px solid $silver;
    }
  }

  .openk9-dataset-card {
    @include box;
    @include boxNoShadow;
    @include boxNoMargin;

    padding: calc($spacing-base * 1);
    width: calc(50% - $spacing-base * 1);
    align-items: center;
    justify-content: space-between;
    overflow-wrap: anywhere;
    gap: 8px;

    > .openk9-dataset-card-title {
      flex: 1;
    }
    > *:last-child {
      width: 40px;
      height: 40px;
    }
  }

  // Logo
  .openk9-no-detail-content,
  .openk9-no-results-container {
    &:before {
      content: "";
      width: 250px;
      height: 150px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .openk9-logo {
      display: none;
    }
  }

  @media (max-width: 1092px) {
  }
  @media (max-width: 992px) {
    // .openk9-filters-horizontal-container {
    //   grid-template-columns: repeat(3, 1fr);
    // }
    // .openk9-filters-container-internal {
    //   flex-direction: column;

    //   .openk9-filter-category-container {
    //     &:first-child {
    //       padding-right: 0;
    //       border-right: 0;
    //     }
    //     &:last-child {
    //       padding-left: 0;
    //     }
    //   }
    // }
    .os-theme-dark > .os-scrollbar-vertical {
      width: 6px;
    }
    .os-theme-dark > .os-scrollbar-horizontal {
      height: 6px;
    }
  }
  @media (max-width: 768px) {
    .select-custom-openk9{
      margin-bottom: 8px;
    }
    // .openk9-filters-horizontal-container {
    //   grid-template-columns: repeat(1, 1fr);
    // }
    // .openk9-dataset-card {
    //   width: 100%;
    // }
  }
}

// HIDDEN

.visually-hidden {
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(
    1px 1px 1px 1px
  ); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(
    1px,
    1px,
    1px,
    1px
  ); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%);
}

.toogle-menuresponsive.btn.btn-primary {
  &::before {
    background: none;
  }

  &::after {
    background: none;
  }

  &:hover {
    background-color: #fff;
    color: #d6012e;
  }
}
