$serif-font-family: Publico, serif;
$sans-font-family: 'Titillium Web','Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

$spacing-base: 8px;

$red: #d6012e;
$stone: #3F3F46;
$mid-stone: #f4f4f5;
$light-stone: #FAFAFA;
$light-grey: #D4D4D8;
$grey: #747481;
$success: #008055;
$dark-grey-secondary:#575760;

$silver: #cccccc;
$sienna: #F07662;
//$light-grey: #ccc;
//$grey: #7f7f7f;
$dark-grey: #71717A;
$lime: #1CC54E;
$negative-red: #C91432;

$min-width-tablet: 813px;