@import './variables.scss';

.fakeHome {
    width: 100%;
}

.root {
    background-color: #f5f5f5;
    padding-bottom: 16px;
    padding-top: 16px;

    .mainContainer {
        max-width: 1140px;
        padding-left: 15px;
        padding-right: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .layout {
        display: grid;
        grid-template:
            "tabs tabs"
            "searchbar searchbar"
            "results details";
        grid-template-columns: 1fr 1.5fr;
        column-gap: 32px;
        row-gap: 32px;

        .tabsContainer {
            grid-area: tabs;
        }
        .searchbarContainer {
            grid-area: searchbar;
            display: grid;
            grid-template:
                "search sort ";
            grid-template-columns: 3fr 1fr ;
            column-gap: 30px;

            .search {
                grid-area: search;
            }
            .sort {
                grid-area: sort;
            }
            // .filtersTrigger {
            //     grid-area: filtersTrigger;
            //     display: flex;

            //     button {
            //         align-self: flex-end;
            //         font-size: 13px;
            //         padding: 8px;
            //         height: 52px;
            //         width: 100%;
            //         &:focus {
            //             box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
            //             outline: 0;
            //         }
            //     }
            // }
            // .filters {
            //     grid-area: filters;
            // }
        }
        .resultsContainer {
            grid-area: results;
            height: 800px;
        }
        .detailsContainer {
            grid-area: details;
        }
    }
    .detailsPanel {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 8px;
        background-color: #cccccc66;
        z-index: 500;

        .detailsPanelContent {
            height: 100%;
            width: 100%;
            background-color: white;
        }
    }
    .box {
        background-color: white;
        border: 1px solid $light-grey;
        border-radius: 8px;
    }
    .pBase {
        padding: 20px 24px;
    }
    .title {
        margin: 0;
        &.thin {
            font-weight: 400;
        }
        &.sm {
            font-size: 18px;
        }
        &.xs {
            font-size: 16px;
            font-weight: 700;
        }
    }
    .floatRight {
        float: right;
    }

    @media (max-width: 1200px) {
        .layout {
            grid-template:
                "tabs tabs"
                "searchbar searchbar"
                "results details";
            grid-template-columns: 3fr 1fr;

            .searchbarContainer {
                grid-template-columns: 3fr 1fr ;
            }
        }
    }
    @media (max-width: 1024px) {
        .layout {
            grid-template:
                "tabs tabs"
                "searchbar searchbar"
                "results results";
            grid-template-columns: 1fr;
    
            .searchbarContainer {
                grid-template:
                    "search sort";
                grid-template-columns: 3fr 1fr;
                row-gap: 30px;
            }
        }
        .detailsContainer {
            display: none;
        }
    }
    
    @media (max-width: 768px) {
        .layout {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto;
            grid-template-areas:
                "tabs tabs"
                "searchbar searchbar"
                "results results";
    
            .searchbarContainer {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-areas: 
                    "search"
                    "sort";
                row-gap: 30px;
            }
        }
        .hiddenMd {
            display: none;
        }
        .pBase {
            padding: 8px 12px;
        }
    }
}