@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap');
@import './variables.scss';

.typo-sans {
  font-family: $sans-font-family;
}

.typo-serif {
  font-family: $serif-font-family;
}

.openk9-text-lg {
  font-size: 20px;
}

.openk9-text-xlg {
  font-size: 24px;
}

.openk9-text-success {
  color: $success;
}