@import './variables.scss';

@mixin box {
  margin: calc($spacing-base * 2);
  padding: calc($spacing-base * 2);
  border: 1px solid $light-grey;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px 0px #00000040;
}

@mixin boxNoShadow {
  box-shadow: initial;
}

@mixin boxNoMargin {
  margin: 0;
}

@mixin boxHighlight {
  padding-top: calc($spacing-base * 2 - 4px);
  border-top: 5px solid $red;
}