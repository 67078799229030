@import './variables.scss';

.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 10px;					// Useful to space icon from text when it's present
	position: relative;
	overflow: hidden;
	padding: 7px 30px;
	font-size: 16px;
	min-height: 30px;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	cursor: pointer;
	outline: none;
	border-style: solid;
	user-select: none;
	border-radius: 0;
	z-index: 0;
	transition: border-color 0.29s cubic-bezier(0.455, 0.030, 0.515, 0.955);
	&:focus {
		box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.25rem #ee4848;
		outline: 0;
	}
	&:disabled {
		pointer-events: none;
	}
	
	small {
		display: block;
	}
}


// Main theme colors

.btn {
	border-radius: 4px;

	&.btn-primary {
		background-color: $red;
		border: 0;
		color: white;
	}
	
	&.btn-secondary {
		background-color: $grey;
		border: 0;
		color: white;
	}
	
	&.btn-ghost {
		border-color: $red;
		color: $red;
		
		&-primary {
			border-color: $red;
			color: $red;
		}
		
		&-secondary {
			border-color: $stone;
			color: $stone;
		}
		
		&,
	    &-white
	    {
	        &,
	        &-primary,
	        &-secondary
	        {
	        	background-color: transparent;
	        }
	    }
	}
	
    &-white,
    &-ghost
    {
        &-primary,
        &-secondary
        {	
        	&:hover {
        		color: white;

				svg {
					fill: white;
				}
        	}
        }
    }
}


// Main Glittering effect on hover

.btn {
	&:after {
		content: "";
		width: 0;
		height: 530px;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -1;
		opacity: 0;
		background: #b3b3b3;
		transform: translate(-50%,-50%) rotate(45deg);
		transform: translate3d(-50%,-50%,0) rotate(45deg);
		backface-visibility: hidden;
		transition: width .3s,opacity .3s;
	}
	
	&:hover::after {
	    width: 100%;
	    opacity: 1;
	}
	
	&.btn-primary,
	&.btn-secondary,
	&.btn-white,
	&.btn-ghost {
		&:before {
			content: '';
			position: absolute;
			z-index: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 2px;
		}
	}
	
	&.btn-primary {
        .openk9-icon {
            svg path {
                fill: white;
            }
        }
		&:before {
			background: scale-color($red, $blackness: 30%);
		}

		&:after {
			background-color: scale-color($red, $blackness: 30%);
		}
	}

	&.btn-secondary {
		&:before {
			background: scale-color($grey, $blackness: 30%);
		}
	
		&:after {
			background-color: scale-color($grey, $blackness: 30%);
		}
	}
	
	&.btn-ghost {
		&:before {
			background: $red;
		}
		
		&:after {
			background-color: white;
		}

		&-white {
			&:after {
				background-color: white;
			}
		}
		
		&-primary {
			&:after {
				background-color: $red;
			}
		}
		
		&-secondary {
			&:after {
				background-color: scale-color($grey, $blackness: 30%);
			}
		}
	}

	/*
	span {
		z-index: 20;
		transition: color 0.29s cubic-bezier(0.455, 0.030, 0.515, 0.955);
	}
	
	&:active,
	&:hover {
		&.btn-primary span {
			color: white;
		}

		&.btn-secondary span {
			color: white;
		}

		&.btn-white span {
			color: white;
		}

		&.btn-white-secondary {
			border-color: $axa-flamingo;
			
			span {
				color: white;
			}
		}
	}
	*/

	&:active,
	&:hover {
		.btn-ghost {
			color: scale-color($red, $blackness: 30%);
			border-color: scale-color($red, $blackness: 30%);
			
			&-white {
				color: $red;
			}
			
			&-primary {
				color: white;
			}
			
			&-secondary {
				color: white;
				border-color: scale-color($grey, $blackness: 30%);
			}
		}
	}
	
		
	// &:disabled {
	// 	opacity: 1;
	// 	background-color: $axa-wild-sand;
	// 	color: $axa-silver;
		
	// 	&:before,
	// 	&:after {
	// 		display: none;
	// 	}
	// }
}


// Different sizes

.btn {
	&.xs {
		padding: 0;
	}
	&.sm,
	&.md,
	&.lg {
		font-weight: 600;	
	}
	
	&.sm {
		height: 30px;
		border-width: 1px;
		font-size: 12px;
		line-height: 12px;
		
		&:active,
		&:hover {
			&:after {
				height: 880%;
			}
		}
	}
	
	&.md {
		height: 30px;
		border-width: 2px;
		font-size: 14px;
		line-height: 14px;
		
		&:active,
		&:hover {
			&:after {
				height: 620%;
			}
		}
	}
	
	&.lg {
		border-width: 2px;
		font-size: 16px;
		line-height: 20px;
		padding: 10px 30px;
		
		&:active,
		&:hover {
			&:after {
				height: 950%;
			}
		}
	}
	
	@media (min-width: $min-width-tablet) {
		&.md {
			height: 40px;
		}
		
		&.lg {
			padding: 15px 30px;
		}
	}
}


.btn-link {
	display: inline-block;
	font-weight: 700;
	// text-transform: uppercase;
	text-decoration: none !important;
	font-size: 14px;
	letter-spacing: .1em;
	line-height: 30px;
	opacity: 1;
	transform: none;
	padding: 0;
	transition: color .3s linear;
	animation-name: fadeInRight;
	
	&:after {
		display: none;
	}
	
	// &:before {
	// 	float: right;
	// 	margin-left: 10px;
	// 	//@include icon($arrow-right);
		
	// 	// Custom icomoon icon
	// 	content: '>';
	// 	font-family: icomoon;
	// }
	
	&:hover {
		text-decoration: none !important;
		
		&:before {
			animation: animate-arrow-right .4s cubic-bezier(.77,0,.175,1);
		}
	}

    &.sm {
        font-size: 12px;
		&:focus-visible{
				border: 2px solid #ee4848;
				outline: 0;
		}
    }
    &.uc {
        text-transform: uppercase;
    }
	
	&-primary {
		color: $red;
		
		&:hover,
		&:focus {
			color: $red;
		}
	}
	
	&-secondary {
		color: $grey;
		
		&:hover,
		&:focus {
			color: $grey;
		}
	}
	
	&-inverted {
		color: white;
		
		&:hover,
		&:focus {
			color: white;
		}
	}
}

@keyframes animate-arrow-right {
	0% {
		transform: translate(0) scale(1);
		transform: translateZ(0) scale(1);
		opacity: 1;
	}
	50% {
		transform: translate(10px) scaleX(1.3);
  		transform: translate3d(10px,0,0) scaleX(1.3);
  		opacity: 0;
	}
	51% {
		transform: translate(-10px) scaleX(1.3);
		transform: translate3d(-10px,0,0) scaleX(1.3);
		opacity: 0;
	}
	to {
		transform: translate(0) scale(1);
		transform: translateZ(0) scale(1);
		opacity: 1;
	}
}
