@import './variables.scss';

@mixin grid-layout-3-template {
  display: grid;
  
  grid-template:
      "area-11 area-12 area-13";
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;

  [class$="11-area"] {
    grid-area: area-11;
  }
  [class$="12-area"] {
    grid-area: area-12;
  }
  [class$="13-area"] {
    grid-area: area-13;
  }

  @media (max-width: 768px) {
    grid-template:
      "area-11"
      "area-12"
      "area-13";
    grid-template-columns: 1fr;
  }

}