@import './variables.scss';

.openk9-flex {
  display: flex;

  &.openk9-flex-wrap {
    flex-wrap: wrap;
  }
  &.g-sm {
    gap: 8px;
  }
}