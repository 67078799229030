@import './variables.scss';

@mixin inputs {
  width: 100%;
  border: 1px solid $grey;
  border-radius: 4px;
  height: 50px;

  &:hover {
    border: 1px solid $red;
  }
}